import { Component } from 'react';
import { Link } from 'react-router-dom';
import styled from "styled-components";

class Header extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        return (
            <HeaderContainer>
                <Link to="/?go=main">
                    <div>MICRO WEATHER</div>
                </Link>
            </HeaderContainer>
        );
    }
}

const HeaderContainer = styled.div`

    font-family:"BebasNeue";

    div {
        padding: 21px 0 21px 0;
        font-size: 35px;
    }
`


export default Header; 